<template>
  <div>
    <div class="main">
      <!-- v-if="type!=1"  -->
      <!-- {{addresslist}} -->

      <div class="vancard-header" @click="goAddress()">
        <div class="vancard-first">
          <span v-if="addresslist.receiver_name" style="margin-right: 20px">{{
            addresslist.receiver_name
          }}</span>
          <span v-if="addresslist.name" style="margin-right: 20px">{{
            addresslist.name
          }}</span>
          <span>{{ addresslist.tel }}</span>
        </div>
        <div class="vancard-second">
          <van-tag
            v-if="addresslist.isDefault == 1"
            plain
            type="danger"
          >默认
          </van-tag>
          <span
            style="margin-left: 10px"
          >{{ addresslist.name }}{{
            addresslist.cityName
          }}{{ addresslist.address }}</span>
        </div>
        <div class="vancard-icon">
          <van-icon name="arrow" />
        </div>
      </div>

      <!--        拼团人数列表-->
      <div class="spellGroupNumberListWrap">
        <div class="spellGroupNumberList">
          <ul class="spellGroupNumberListContent">
            <li
              v-for="(item, index) in spellGroupNumberList"
              :key="index"
              class="spellGroupNumberListContentLi1"
            >
              <div v-if="item.headpic == ''" class="spellGroupImg2">?</div>
              <div v-else class="spellGroupImg1">
                <img :src="item.headpic" alt="">
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="spellGroupNumberListTextWrap">
                <p class="spellGroupNumberListText">已有{{tuxedoNum}}人参与拼团，6人即可成团，随机3人获得半价商品，中奖之后金额将返还钱包</p>
            </div> -->

        <!-- <div class="spellGroupNumberListBtn">
          <div
            class="spellGroupNumberListBtn1"
            v-if="isPay == 0"
            @click="InviteFriends"
          >
            邀请好友拼团
          </div>
          <div v-else>
            <div
              class="spellGroupNumberListBtn2"
              v-if="type == 1"
              @click="goPay"
            >
              我要开团
            </div>
            <div class="spellGroupNumberListBtn2" v-else @click="goPay">
              我要参团
            </div>
          </div>
        </div> -->
      </div>

      <!--        商品信息-->
      <div class="goodsInfoWrap">
        <div class="goodsImg">
          <img :src="goodsImg" alt="">
        </div>
        <div class="goodsInfo">
          <p class="goodsName">{{ goodsName }}</p>
          <p class="goodsSpecifications">{{ goodsSpecifications }}</p>
          <p class="goodsPrice">¥ {{ goodsPrice }}</p>
        </div>
      </div>
      <div
        v-if="type == 2"
        style="width: 100%; height: 10px; background-color: #f8f8f8"
      />
      <!--        选择拼团人数-->
      <div class="spellGroupNumWrap">
        <van-cell-group
          style="text-align: left; margin: 10px auto; font-size: 12px"
        >
          <van-cell title="商品金额" :value="'¥' + totalPrice" />
          <!-- <van-cell title="优惠金额" :value="'¥' + blanceInfolist.totalFreight" /> -->
          <van-cell title="运费" :value="'¥' + 0" />
          <!-- <van-cell
          v-if="type != 1 && type != 2"
          title="悦豆"
          is-link
          value="无可用"
        /> -->
        </van-cell-group>

        <!-- <p class="spellGroupNum">规则描述</p> -->
        <!-- <p class="spellGroupDescribe">{{goodsDescribe}}</p> -->
      </div>
      <div style="width: 100%; height: 10px; background-color: #f8f8f8" />

      <!--        btm支付-->
      <div v-if="isPay != 0" class="spellGroupBtm">
        <div class="spellGroupBtmText">
          <span>合计:</span>
          <span style="color: #f7263c"> ¥ {{ goodsPrice }}</span>
          <!-- <span style="color:#f7263c" v-if="$route.query.type==2"> ¥ {{ goodsPrice }}</span>
          <span style="color:#f7263c"  v-if="$route.query.type==1"> ¥ 0</span> -->
          <!-- <span>元(中奖立返余额)</span> -->
        </div>
        <div class="spellGroupBtmBtn" @click="goPay">去支付</div>
      </div>
    </div>
    <!--    拼团弹窗   -->
    <div
      v-if="$store.state.showSku"
      class="mask"
      @click.stop="$store.commit('changeShowSku', false)"
    >
      <div class="sku" @click.stop="$store.commit('changeShowSku', true)">
        <div class="tp">
          <img class="goods-cover" :src="goodsImg">
          <div class="goods-price">
            <div class="pris">
              <span class="spe-icon">¥</span>
              <span class="hjy-count">{{ origin_price }}</span>
              <span class="gwj">官网价¥{{ team_price }}</span>
            </div>
            <div class="guige">已选：{{ txt }}</div>
          </div>
        </div>
        <div class="out">
          <div class="inner">
            <div
              v-for="(item, index) in saleList"
              :key="index"
              class="status-box"
            >
              <div class="tits">{{ item.title }}</div>
              <div class="status">
                <span v-for="(ele, idx) in item.buttons" :key="idx">
                  <span
                    :class="skuids[index][idx] ? 'box act' : 'box'"
                    @click="choseSku(index, idx)"
                  >{{ ele.text }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="num-box">
          <div>数量</div>
          <div class="handles">
            <div class="edge" @click="handleCount(false)">-</div>
            <div class="cou">{{ goodsCount }}</div>
            <div class="add" @click="handleCount(true)">+</div>
          </div>
        </div>
        <div class="code-txt">商品编号{{ goodsCode }}</div>
        <div class="btn-box">
          <div class="btn3" @click="goSpellGroup">参与拼团</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import {
  spellGroupOrderConfirm,
  getSpellGroupDescribe,
  getGoodsDetail,
  SpellGroupOrderSubmit,
  skuChangeInfo
} from '@/services/goods.js'
import { addressH5List } from '@/services/userApi'
import Vue from 'vue'
import { Cell, CellGroup, Icon, Tag, Toast } from 'vant'
import { wxConfigInit } from '@/utils/wx-api'

Vue.use(Tag)
  .use(Icon)
  .use(CellGroup)
  .use(Cell)

export default {
  name: 'SpellGroupDetail',
  data() {
    return {
      goodsImg: '', // 商品图片
      goodsName: '', // 商品名称
      goodsSpecifications: '', // 规格
      goodsPrice: '', // 金额
      payPrice: '',
      goodsDescribe:
        '6人即可成团，随机3人 获得半价商品，中奖之后金额将返还钱包', // 描述
      spellGroupNumberList: 6,
      spellGroupImgList: [], // 头像list
      tuxedoNum: '', // 参团人数
      type: 1, // 团规则1开团2拼团
      order_sn: '', // 订单号
      found_id: '0',
      isPay: 99, // 支付状态 0支付成功1等待买家付款2支付失败
      goodsInfo: {},
      goodsCount: 1, // 商品数量
      goodsCode: '', // 商品编码
      saleList: [], // 商品规格
      skuids: [], // 规格选中样式处理
      origin_price: '',
      team_price: '',
      txt: '',
      addresslist: [],
      isFree: 0,
      totalPrice: 0
    }
  },
  created() {
    if (this.GetQueryString('found_id')) {
      // 团ID（分享后进入页面时从url中获取）
      this.found_id = this.GetQueryString('found_id')
    }
    if (
      (this.$route.query.ordersn && this.$route.query.type) ||
      (this.GetQueryString('ordersn') && this.$route.query.type)
    ) {
      this.type = Number(this.$route.query.type) // 团类型
      this.isPay = Number(this.$route.query.isPay) // 支付状态
      this.uid = window.localStorage.getItem('uid')
      this.order_sn =
        this.$route.query.ordersn || this.GetQueryString('ordersn') // 订单号
      this.getSpellGroupDescribe() // 支付后查看详情或分享后团员查看详情
      this.getShippingAddress()
    } else {
      this.spellGroupOrderConfirm() // 拼团订单确认
    }
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        function(position) {
          this.lon = position.coords.longitude
          this.lat = position.coords.latitude
        },
        function(e) {
          // store.commit(e.message, true);
        }
      )
    } else {
      // Toast('位置信息获取失败');
    }
  },
  mounted() {
    this.configShare()
  },
  methods: {
    GetQueryString(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // get地址
    getShippingAddress() {
      const that = this
      const data = {
        mid: window.localStorage.getItem('uid')
      }
      addressH5List(data).then((res) => {
        if (Number(res.status) === 200) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].isDefault == 1) {
              that.addresslist = res.data[i]
              that.$store.commit('handleAddressInfo', res.data)
            }
          }
          // that.$store.state.text.tip
        }
      })
    },
    // 选地址
    goAddress() {
      this.$router.push({
        path:
          '/addressList?id=' + this.addresslist.addressId ||
          this.addresslist.address_id
      })
    },
    // 拼团订单确认
    spellGroupOrderConfirm() {
      const _this = this
      if (window.localStorage.getItem('uid')) {
        // this.$route.meta.title = '';
        if (this.$route.query.type) {
          _this.type = this.$route.query.type
        } else {
          _this.type = this.GetQueryString('type')
        }
        const data = {
          team_id: this.$route.query.team_id, // id  '774',//
          goods_id: this.$route.query.goods_id, // 商品id '395608',
          sku_id: this.$route.query.sku_id, // '436936',
          uid: window.localStorage.getItem('uid'), // 用户id '1625104',
          goods_num: this.$route.query.goods_num, // 商品数量 '1',
          area_id: this.$route.query.area_id, // 地址ID '703576',
          type: _this.type, // 1开团2参团  '1',
          found_id: this.found_id // 团ID（开团没有，参团从分享地址中获取） '0',
        }
        spellGroupOrderConfirm(data).then((res) => {
          if (Number(res.code) === 200) {
            if (window.localStorage.getItem('addressItem')) {
              const ads_data = JSON.parse(
                window.localStorage.getItem('addressItem')
              )
              this.addresslist = ads_data
            } else {
              this.addresslist = res.data.areaInfo
            }
            this.totalPrice = res.data.totalPrice
            this.goodsImg = res.data.productInfo.goods_img
            this.goodsName = res.data.productInfo.goods_name
            this.goodsSpecifications = res.data.productInfo.skuText
            if (res.data.isFree) {
              this.goodsPrice = res.data.totalPrice // 团长0.01元
            } else {
              this.goodsPrice = res.data.productInfo.team_price
            }
            let tuxedoNums = 0
            for (let i = 0; i < res.data.team.length; i++) {
              if (
                res.data.team[i].headpic !=
                'https://gateway.yuetao.vip/shop/v1/images/team/user_default.png'
              ) {
                tuxedoNums++
              }
            }
            this.tuxedoNum = tuxedoNums // 参团人数
            this.isFree = res.data.isFree // 参团人数
            // for (let i = 0; i <= 6; i++) {
            //   if (i > res.data.team.length) {
            //     let newData = {
            //       headpic: "",
            //       id: "",
            //       nickname: "",
            //       payType: "",
            //       type: "",
            //     };
            //     res.data.team.push(newData);
            //   }
            // }
            this.spellGroupNumberList = res.data.team // 参团列表
          } else {
            // 此处为空
          }
        })
      } else {
        this.$router.push({ path: '/login' })
      }
    },
    // get订单详情
    getSpellGroupDescribe() {
      const data = {
        ordersn: this.order_sn, // 订单号--分享后或支付后打开订单详情传参来的
        type: this.type, // 团类型1开团2参团
        uid: this.uid
      }
      getSpellGroupDescribe(data).then((res) => {
        if (Number(res.code) === 200) {
          if (window.localStorage.getItem('addressItem')) {
            const ads_data = JSON.parse(
              window.localStorage.getItem('addressItem')
            )
            this.addresslist = ads_data
          } else {
            this.addresslist = res.data.areaInfo
          }
          this.goodsInfo = res.data
          this.goodsDescribe = res.data.desc // 规则描述
          // 商品信息
          this.goodsImg = res.data.productInfo.goods_img // 商品图片
          this.goodsName = res.data.productInfo.goods_name // 商品名称
          // 商品规格(接口没有该字段)
          this.goodsSpecifications = res.data.productInfo.skuText
          // 原价
          this.origin_price = res.data.productInfo.origin_price
          // 官网价
          this.team_price = res.data.productInfo.team_price
          // 已选
          this.txt = ''
          this.getGoodsDetail(this.$route.query.id)
          if (this.type == 1) {
            this.goodsPrice = 0.01 // 团长0.01元
          } else {
            this.goodsPrice = res.data.productInfo.team_price
          }
          // 规则描述
          this.goodsDescribe =
            '6人即可成团，随机3人 获得半价商品，中奖之后金额将返还钱包'
          // 拼团人员列表
          let tuxedoNums = 0
          for (let i = 0; i < res.data.team.length; i++) {
            if (
              res.data.team[i].headpic !=
              'https://gateway.yuetao.vip/shop/v1/images/team/user_default.png'
            ) {
              tuxedoNums++
            }
          }
          this.tuxedoNum = tuxedoNums // 参团人数
          for (let i = 0; i <= 6; i++) {
            if (i > res.data.team.length) {
              const newData = {
                headpic: '',
                id: '',
                nickname: '',
                payType: '',
                type: ''
              }
              res.data.team.push(newData)
            }
          }
          this.spellGroupNumberList = res.data.team // 参团列表
          // 团长支付完成查看详情不显示btm支付栏，团员首次进入页面未支付显示btm支付栏
        }
      })
    },
    // 商品详情
    getGoodsDetail(goods_id) {
      // 获取自营详情
      const data = {
        id: goods_id, // team_id '774',
        uid: this.uid, // 用户ID '1625104',
        lon: this.lon, // '116.52052652994792'
        lat: this.lat // '39.903980848524306',
      }
      getGoodsDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          this.saleList = res.data.productInfo.saleList
          const mk = []
          this.saleList.forEach((item, index) => {
            mk[index] = []
            item.buttons.forEach((ele, idx) => {
              idx === 0 ? mk[index].push(true) : mk[index].push(false)
            })
          })
          this.skuids = mk
          this.handleChose(0)
        } else {
          this.$router.go(-1)
        }
      })
    },
    choseSku(index, idxs) {
      // 选择规格
      const mk = []
      this.saleList[index].buttons.forEach((ele, idx) => {
        idx === idxs ? mk.push(true) : mk.push(false)
      })
      this.$set(this.skuids, index, mk)
      this.handleChose(idxs)
    },
    handleChose(idxs) {
      // 处理选中的规格，显示在‘已选’文案上
      const sign = []
      this.skuids.forEach((item, index) => {
        item.forEach((ele, idx) => {
          ele ? sign.push(idx) : null
        })
      })
      this.sign = sign
      let txt = ''
      sign.forEach((item, index) => {
        txt = txt + ' ' + this.saleList[index].buttons[item].text
      })
      this.txt = txt
      this.handleSkuId() // 处理规格ID
      if (this.$store.state.addressReady) {
        this.skuChangeGoodsInfo(idxs)
      } else {
        setTimeout(() => {
          this.skuChangeGoodsInfo(idxs)
        }, 1000)
      }
    },
    skuChangeGoodsInfo(idxs) {
      const live_id = this.$route.query.liveid || 0
      const shareId = localStorage.getItem('shareCodeNumber')
      sessionStorage.setItem('live_id', live_id)
      this.$store.commit('changeShowLoading', 1)
      const data = {
        shareId,
        uid: window.localStorage.getItem('uid') || '',
        productSkuId: this.goodsCode,
        productType: 0,
        liveId: this.$route.query.liveid || 0,
        addressCode: this.$store.state.addressInfo.areaIds || 0,
        lon: 0,
        lat: 0
      }
      skuChangeInfo(data).then((res) => {
        if (Number(res.code) === 200) {
          this.goodsInfo.productInfo['banner'] = [res.data.img]
          this.goodsInfo.goodPrice = res.data.nowPrice
          // this.$store.commit("changeSkuIdx", idxs);
        }
      })
    },
    handleSkuId() {
      // 处理规格ID
      const goodsInfo = this.saleList
      const mk1 = []
      let mk2 = []
      let mkArr = []
      for (let i = 0; i < goodsInfo.length; i++) {
        mkArr = mkArr.concat(goodsInfo[i].buttons[this.sign[i]].skuList)
      }
      if (mkArr.length <= 1) {
        mk2 = mkArr
      } else {
        mkArr.forEach((item, index) => {
          if (mk1.indexOf(item) === -1) {
            mk1.push(item)
          } else {
            mk2.push(item)
          }
        })
      }
      this.goodsCode = mk2[0]
    },
    handleCount(bol) {
      // 减：false 加：true
      if (bol) {
        this.goodsCount++
      } else {
        if (this.goodsCount == 1) {
          Toast('不能再少了呦')
          return
        }
        this.goodsCount--
      }
    },
    // 去支付   我要开团
    goPay() {
      if (this.$route.query.isPay) {
        this.$store.commit('changeShowSku', true)
      } else {
        if (this.isFree === 1) {
          this.freeGroupTuxedo()
        } else {
          this.$router.push({
            path: '/placeOder',
            query: {
              team_id: this.$route.query.team_id,
              goodsId: this.$route.query.goods_id,
              product_sku_id: this.$route.query.sku_id,
              type: this.type,
              found_id: this.found_id,
              areaId: this.$route.query.areaIds,
              productNum: this.$route.query.goods_num,
              addressIds: this.$route.query.area_id
            }
          })
        }
      }
    },
    /**
     * 团长免费开团逻辑
     */
    freeGroupTuxedo() {
      const { team_id, goods_id, sku_id } = this.$route.query
      const params = {
        team_id: team_id,
        goods_id: goods_id,
        sku_id: sku_id,
        type: this.type,
        found_id: this.found_id,
        area_id: this.$route.query.area_id,
        goods_num: this.$route.query.goods_num,
        addressIds: this.$route.query.area_id,
        'channel': '3', //
        'uid': window.localStorage.getItem('uid'), // 用户ID
        'orderFrom': 1004, // 来源
        'position_from': '0'
      }
      SpellGroupOrderSubmit(params).then((res) => {
        if (Number(res.code) === 200) {
          this.$router.replace({
            path: '/Shopping_details',
            query: {
              ordersn: res.data.order_sn,
              type: this.type
            }
          })
        }
      })
    },
    goSpellGroup() {
      this.$router.push({
        path: '/placeOder',
        query: {
          team_id: this.goodsInfo.productInfo.team_id,
          goodsId: this.goodsInfo.productInfo.goods_id,
          product_sku_id: this.goodsInfo.productInfo.sku_id,
          found_id: this.goodsInfo.productInfo.found_id,
          type: this.$route.query.type,
          productNum: this.goodsCount,
          addressIds: this.addresslist.id,
          areaId: this.addresslist.areaIds
        }
      })
    },
    // 邀请好友拼团
    InviteFriends() {
      this.configShare()
      Toast('点击右上角进行分享')
    },
    configShare() {
      const that = this
      wxConfigInit(that.shareInfo)
      // this.$store.dispatch('wxConfigInit', that.shareInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #f6f6f6;
}

.goodsInfoWrap {
  display: flex;
  flex: 4;
  background-color: #fff;
  padding: 10px;
}

.goodsImg {
  flex: 1;
  width: 92px;
  height: 92px;
  border-radius: 5px;
}

.goodsImg img {
  width: 100%;
  height: 100%;
}

.goodsInfo {
  flex: 3;
  margin-left: 10px;
  margin-top: 6px;
  text-align: left;
}

.goodsName {
  font-size: 13px;
  color: #000;
  font-weight: 700;
  line-height: 20px;
}

.goodsSpecifications {
  color: #999;
  font-size: 11px;
  margin-bottom: 13px;
}

.goodsPrice {
  color: #f7263c;
  font-size: 11px;
}

.spellGroupNumWrap {
  width: 100%;
  background-color: #fff;
  margin-top: 7px;
  text-align: left;
  padding: 10px;
}

.spellGroupNum {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.spellGroupDescribe {
  font-size: 12px;
  color: #666;
  font-weight: 400;
}

.spellGroupNumberListWrap {
  width: 100%;
  background-color: #fff;
  padding: 10px 10px;
}

/*拼团人员头像列表*/
.spellGroupNumberList {
  width: 100%;
}

.spellGroupNumberListContent {
  margin: 0 auto;

  // display: flex;
  // flex: 6;
}

.spellGroupNumberListContentLi1 {
  display: inline-block;
  // flex: 1;
  vertical-align: middle;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  margin-right: 10px;
}

.spellGroupNumberListContentLi2 {
  margin-right: 0px;
}

.spellGroupImg1 {
  width: 43px;
  height: 43px;
  border: 1px solid #f7263c;
  border-radius: 50%;
}

.spellGroupImg1 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.spellGroupImg2 {
  width: 43px;
  height: 43px;
  text-align: center;
  line-height: 43px;
  font-size: 24px;
  color: #ededed;
  font-weight: 700;
  border-radius: 50%;
  border: 1px solid #f7263c;
  background-color: #ffffff;
}

.spellGroupNumberListTextWrap {
  margin-top: 17px;
  color: #000;
  font-size: 11px;
  font-weight: 400;
}

.spellGroupNumberListText {
  line-height: 15px;
}

.spellGroupNumberListBtn {
  display: flex;
  flex: 2;
  margin-top: 27px;
}

.spellGroupNumberListBtn1,
.spellGroupNumberListBtn2 {
  flex: 1;
  width: 123px;
  height: 40px;
  background-color: #f7263c;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 20px;
}

.spellGroupNumberListBtn1 {
  margin-right: 13px;
}

.spellGroupBtm {
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 0;
  // border-top: 1px solid #757575;
  display: flex;
  flex: 3;
  // padding: 13px;
  background: #fff;
}

.spellGroupBtmText {
  flex: 2;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 35px;
}

.spellGroupBtmBtn {
  flex: 1;
  width: 120px;
  height: 40px;
  background-color: #f7263c;
  // border-radius: 17px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      //  height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .sku {
    width: 100%;
    min-height: 500px;
    border-radius: 8px 8px 0 0;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 14px;

    .tp {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      .goods-cover {
        width: 78px;
        height: 78px;
        border-radius: 4px;
        background: #e0e0e0;
      }

      .goods-price {
        width: 250px;
        height: 78px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding-left: 12px;

        .pris {
          text-align: left;

          .hjy {
            color: #ed2e2e;
            font-size: 13px;
          }

          .hjy-count {
            color: #ed2e2e;
            font-size: 18px;
            font-weight: bold;
            margin-right: 12px;
          }

          .spe-icon {
            font-size: 14px;
            color: #ed2e2e;
            font-weight: bold;
            margin-left: 4px;
          }

          .gwj {
            color: #141f33;
            font-size: 13px;
          }
        }

        .guige {
          text-align: left;
          font-size: 13px;
          color: #99a0ad;
        }

        .close {
          width: 16px;
          height: 16px;
          background: url("../../assets/images/closesku.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }

    .out {
      height: 200px;
      overflow: hidden;
      margin-bottom: 50px;

      .inner {
        height: 200px;
        overflow: auto;
      }
    }

    .status-box {
      width: 100%;
      margin-bottom: 8px;

      .tits {
        font-size: 16px;
        color: #141f33;
        margin-bottom: 12px;
        text-align: left;
      }

      .status {
        width: 100%;
        overflow: hidden;

        .box {
          width: auto;
          padding: 6px 20px;
          background: #f0f2f5;
          border-radius: 4px;
          font-size: 14px;
          color: #141414;
          margin-right: 12px;
          margin-bottom: 12px;
          float: left;
        }

        .act {
          background: #fae9e8;
          color: #ed2e2e;
        }
      }
    }
  }

  .num-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #141f33;
    margin-bottom: 23px;

    .handles {
      .edge,
      .add {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
      }

      .cou {
        display: inline-block;
        width: 45px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
        margin: 0 4px;
      }
    }
  }

  .code-txt {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #99a0ad;
    margin-bottom: 4px;
  }

  .btn-box {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .btn1 {
      width: 50%;
      height: 100%;
      line-height: 44px;
      background: #201e1d;
      color: #fff;
      font-size: 16px;
    }

    .btn2 {
      background: #ff001c;
    }

    .btn3 {
      width: 100%;
      height: 100%;
      line-height: 44px;
      color: #fff;
      font-size: 16px;
      background: #ff001c;
    }
  }
}

.vancard-header {
  width: 100%;
  overflow: auto;
  position: relative;
  line-height: 25px;
  background: #fffef3;
  padding: 10px 0;

  .vancard-first {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-left: 20px;
  }

  .vancard-second {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 10px;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vancard-icon {
    position: absolute;
    right: 20px;
    top: 30px;
    font-size: 18px;
  }
}

.vancard-header::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.05333rem;
  background: -webkit-repeating-linear-gradient(135deg,
  #ff6c6c 0,
  #ff6c6c 20%,
  transparent 0,
  transparent 25%,
  #1989fa 0,
  #1989fa 45%,
  transparent 0,
  transparent 50%);
  background: repeating-linear-gradient(-45deg,
  #ff6c6c 0,
  #ff6c6c 20%,
  transparent 0,
  transparent 25%,
  #1989fa 0,
  #1989fa 45%,
  transparent 0,
  transparent 50%);
  background-size: 2.13333rem;
  content: "";
}

.van-cell__value {
  color: red;
}
</style>
